import React from "react";

function Services() {
  const services = [
    { image: "pexels-kampus-8428103.jpg", heading: "Accounting & Bookkeeping Services", description: "We provide accurate and efficient bookkeeping services that include transaction management, financial statement preparation, and tax filings. Our accounting solutions ensure that your financial health is managed in real-time, allowing for proactive decision-making." },
    { image: "pexels-kampus-8428059.jpg", heading: "Audit & Assurance", description: "Our audit services provide critical insight into your business's financial health, ensuring compliance with regulations and helping you make informed decisions. We offer both internal and external audits, tailored to meet the specific needs of your business and stakeholders." },
    { image: "pexels-mart-production-7643738.jpg", heading: "VAT Consultancy", description: "In a jurisdiction like the UAE, VAT regulations can be complex. Our VAT consultancy ensures that you remain compliant while optimizing your tax planning strategies. We assist with VAT registration, filing, and audits to reduce tax burdens and enhance profitability." },
    { image: "pexels-mikhail-nilov-8101767.jpg", heading: "Corporate Tax Consultancy", description: "We offer comprehensive corporate tax planning and consultancy services to help businesses minimize their tax liabilities and ensure compliance with UAE corporate tax laws and international tax regulations." },
    { image: "pexels-mikhail-nilov-8101767.jpg", heading: "Liquidation Services", description: "If your business is undergoing restructuring or closure, we provide full liquidation services, ensuring that the process is handled in accordance with local laws while minimizing disruption to operations." },
    { image: "pexels-mikhail-nilov-8101767.jpg", heading: "Corporate Services", description: "We assist businesses with company formation, restructuring, and compliance with legal and regulatory frameworks. Our team navigates through the complexities of local laws to provide smooth and efficient corporate services." },
    { image: "pexels-mikhail-nilov-8101767.jpg", heading: "Risk & Compliance", description: "Our risk and compliance services are designed to help businesses adhere to international standards, including AML/CFT compliance. We offer risk advisory, ensuring that you remain compliant with both local and international regulations while minimizing exposure to operational and financial risks." },
  ];
  return (
    <>
      <section id="services">
        <i className="fi fi-tr-accident"></i>
        <div className="flex overflow-hidden">
          <div className="services-left-side md:w-1/3 bg-cover bg-center hidden md:block"></div>
          <div className="bg-[#E4F1F1] p-12 pr-0 md:w-2/3 services-second-row relative w-full">
            <div className="rounded-md bg-[#00c18a1a] py-0.5 px-2.5 border border-transparent text-sm theme-text-color transition-all shadow-sm w-fit">
              Our Services
            </div>
            <div className="text-5xl heading-font-family w-2/3 my-5">
              The type of service provided as financial advisory
            </div>
            <div className="flex overflow-scroll gap-8 no-scrollbar z-10 relative">
              {services.map((value, index) => {
                return (
                  <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 services-card">
                    <a href="/">
                      <img
                        className="rounded-t-lg"
                        src={`./assets/images/${value.image}`}
                        alt=""
                      />
                    </a>
                    <div className="p-5">
                      <a href="/">
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                          {value.heading}
                        </h5>
                      </a>
                      <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                        {value.description}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Services;
