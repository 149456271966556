import React from "react";
import WorkProcess from "../components/WorkProcess";
import { Clock } from "react-flaticons";
import BannerWithoutImage from "../components/BannerWithoutImage";

function About() {
  return (
    <>
      <BannerWithoutImage
        title="About"
        description="Al Miswan is a trusted partner for businesses, offering top-tier Accounting, Auditing, and Taxation solutions. Headquartered in Dubai, we cater to the financial needs of businesses locally and across the globe. Our strength lies in combining market research, financial expertise, and innovative solutions to help businesses optimize their operations and thrive in a dynamic environment."
      />
      <section className="container mx-auto" id="about-welcome">
        <div className="grid grid-rows-1 md:grid-cols-2 grid-cols-1 gap-16">
          <div className="image hidden md:block">
            <img
              src="/assets/images/about.webp"
              className="h-full object-contain"
              alt=""
            />
          </div>
          <div className="my-16">
            <div className="rounded-md bg-[#00c18a1a] py-0.5 px-2.5 border border-transparent text-sm theme-text-color transition-all shadow-sm w-fit">
              Our Services
            </div>
            <div className="lg:text-4xl text-2xl heading-font-family my-5 font-bold">
              Our firm operates worldwide business since 1998.
            </div>
            <p className="mb-3 lg:text-2xl text:xl text-gray-700 dark:text-gray-400">
              We specialize in providing accounting, taxation and payroll
              processing services.
            </p>
            <div className="about-paras">
              <div className="flex gap-5 items-start">
                <img src="/assets/icon/mission.svg" alt="mission-icon" />
                <div className="flex flex-col gap-4">
                  <span className="font-bold">Our mission</span>
                  <span className="text-gray-700 leading-relaxed">
                    Our mission is to empower businesses by delivering
                    comprehensive, technically-backed financial solutions. We
                    strive to help clients optimize their financial processes,
                    enhance compliance, and improve their competitive
                    positioning. By leveraging our cutting-edge expertise, we
                    aim to contribute to the long-term success of our clients,
                    helping them scale with confidence and ease.
                  </span>
                </div>
              </div>
              <div className="flex gap-5 items-start mt-10">
                <img src="/assets/icon/vision.svg" alt="mission-icon" />
                <div className="flex flex-col gap-4">
                  <span className="font-bold">Our vision</span>
                  <span className="text-gray-700 leading-relaxed">
                    Our vision is "Efficiency with Accuracy", a guiding
                    principle that reflects our dedication to providing precise
                    and timely financial services. We aim to be a global leader
                    in accounting, tax, and corporate consultancy by offering
                    solutions that not only address the needs of today but
                    anticipate the demands of tomorrow.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WorkProcess />
      <section id="features" className="bg-[#242424] py-32">
        <div className="container mx-auto">
          <div className="rounded-md bg-[#00c18a1a] py-0.5 px-2.5 border border-transparent mx-auto text-sm theme-text-color transition-all shadow-sm w-fit">
            Features
          </div>
          <div className="lg:text-4xl text-2xl heading-font-family my-5 font-bold text-white text-center mx-auto w-1/2">
            Here are some features why you should choose us
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-8 mt-16">
            <div className="flex">
              <div className="flex flex-col gap-4 justify-center text-gray-400 bg-[#1A1A1A] p-8 md:w-1/2 w-full">
                <span className="text-white text-xl font-bold heading-font-family">
                  Cost efficiency
                </span>
                <span>
                  When it comes to cost efficiency in Accounting, Taxation &
                  Payroll Processing Services, here are some key points to
                  consider
                </span>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-2">
                    <img src="/assets/icon/check.svg" alt="" />
                    <span>Elimination of penalties</span>
                  </div>
                  <div className="flex gap-2">
                    <img src="/assets/icon/check.svg" alt="" />
                    <span>Technology efficiency</span>
                  </div>
                  <div className="flex gap-2">
                    <img src="/assets/icon/check.svg" alt="" />
                    <span>Access to expertise</span>
                  </div>
                  <div className="flex gap-2">
                    <img src="/assets/icon/check.svg" alt="" />
                    <span>Focus on core business</span>
                  </div>
                  <div className="flex gap-2">
                    <img src="/assets/icon/check.svg" alt="" />
                    <span>Risk management</span>
                  </div>
                </div>
              </div>
              <div
                className="w-1/2 hidden md:block"
                style={{ height: "484px" }}
              >
                <img
                  src="/assets/images/choose-us.webp"
                  className="h-full"
                  alt=""
                />
              </div>
            </div>
            <div className="flex flex-col h-full justify-between gap-3">
              <div className="flex bg-[#1A1A1A] p-4 gap-6">
                <div>
                  <Clock className="theme-text-color" size={32} />
                </div>
                <div className="flex flex-col text-gray-400 gap-4">
                  <span className="text-white text-xl font-bold heading-font-family">
                    Comprehensive Solutions
                  </span>
                  <span>
                    We offer a full suite of accounting, audit, tax, and
                    corporate services under one roof.
                  </span>
                </div>
              </div>
              <div className="flex bg-[#1A1A1A] p-4 gap-6">
                <div>
                  <Clock className="theme-text-color" size={32} />
                </div>
                <div className="flex flex-col text-gray-400 gap-4">
                  <span className="text-white text-xl font-bold heading-font-family">
                    Trusted by Clients
                  </span>
                  <span>
                    Our clients rely on our professional expertise and
                    personalized services to support their business growth.
                  </span>
                </div>
              </div>
              <div className="flex bg-[#1A1A1A] p-4 gap-6">
                <div>
                  <Clock className="theme-text-color" size={32} />
                </div>
                <div className="flex flex-col text-gray-400 gap-4">
                  <span className="text-white text-xl font-bold heading-font-family">
                    Global Reach
                  </span>
                  <span>
                    While based in Dubai, our expertise and services span across
                    borders, providing global solutions to international
                    clients.
                  </span>
                </div>
              </div>
              <div className="flex bg-[#1A1A1A] p-4 gap-6">
                <div>
                  <Clock className="theme-text-color" size={32} />
                </div>
                <div className="flex flex-col text-gray-400 gap-4">
                  <span className="text-white text-xl font-bold heading-font-family">
                    Tailored Approach
                  </span>
                  <span>
                    We provide customized strategies that align with your
                    specific business goals and challenges.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
