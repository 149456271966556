import React from 'react'

function BannerWithoutImage({title, description = null}) {
  return (
    <section
        className="bg-[#E7E0F2] flex justify-center items-center relative flex-col"
        style={{ height: "32rem" }}
      >
        <h1 className="lg:text-7xl text-6xl font-bold text-gray-800 heading-font-family lg:text-left text-center">
          {title}
        </h1>
        {description && <p className='w-1/2 pt-5 text-center'>{description}</p>}
        <div className='absolute top-0 left-0'>
            <img src="/assets/images/page-banner-shape1.png" alt="" />
        </div>
        <div className='absolute bottom-0 right-0'>
            <img src="/assets/images/page-banner-shape1.png" alt="" />
        </div>
      </section>
  )
}

export default BannerWithoutImage