import { faSearchengin } from "@fortawesome/free-brands-svg-icons";
import { faCommentsDollar, faHandshake } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function WorkProcess() {
  return (
    <>
      <section id="work-process" className="py-32">
        <div className="rounded-md bg-[#00c18a1a] py-0.5 px-2.5 border border-transparent text-sm theme-text-color transition-all shadow-sm w-fit mx-auto">
          Work Process
        </div>
        <div className="text-5xl heading-font-family text-center w-2/3 mx-auto">
          The work process of accounting, taxation & payroll processing services
        </div>
        <div className="flex justify-evenly items-center flex-col md:flex-row mt-24">
          <div
            className="flex bg-[#F8F5F2] flex-col work-process-balls relative icon"
            data-font="need"
          >
            <div className="work-process-icons absolute text-white theme-bg-dark-color rounded-full text-2xl p-4">
              <FontAwesomeIcon icon={faCommentsDollar} />
            </div>
            <span className="font-bold">Your need</span>
            <span>
              Personal financial advisors tailor strategies to meet your unique
              financial goals.
            </span>
          </div>

          <div className="arrow hidden lg:block">
            <img
              decoding="async"
              src="https://themes.envytheme.com/aegis/wp-content/uploads/2023/08/process-arrow.png"
              alt="images"
            />
          </div>
          <div
            className="flex bg-[#F8F5F2] flex-col work-process-balls relative"
            data-font="research"
          >
            <div className="work-process-icons absolute text-white theme-bg-dark-color rounded-full text-2xl p-4">
              <FontAwesomeIcon icon={faSearchengin} />
            </div>
            <span className="font-bold">Research</span>
            <span>
              Personal financial advisors tailor strategies to meet your unique
              financial goals.
            </span>
          </div>
          <div className="arrow hidden lg:block">
            <img
              decoding="async"
              src="https://themes.envytheme.com/aegis/wp-content/uploads/2023/08/process-arrow.png"
              alt="images"
            />
          </div>
          <div
            className="flex bg-[#F8F5F2] flex-col work-process-balls relative"
            data-font="confirm"
          >
            <div className="work-process-icons absolute text-white theme-bg-dark-color rounded-full text-2xl p-4">
              <FontAwesomeIcon icon={faHandshake} />
            </div>
            <span className="font-bold">Confirm</span>
            <span>
              Personal financial advisors tailor strategies to meet your unique
              financial goals.
            </span>
          </div>
        </div>
      </section>
    </>
  );
}

export default WorkProcess;
