import React from "react";
import {
  faEnvelope,
  faClock,
  faChevronDown,
  faPhoneVolume,
  faGlobe,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
function Header() {
  // const menu = useState([{path:'/', value: 'Home'}, {path:'/', value: 'About'}]);
  const location = useLocation();
  console.log(location);
  return (
    <>
      <header className={`${location.pathname === '/' && 'absolute mt-2'} z-30 w-full`}>
        <div
          className={`top-header-area relative z-30 ${
            location.pathname !== "/" && "hidden"
          }`}
        >
          <div className="container mx-auto max-w-screen-xl text-gray-100">
            <div className="flex justify-between md:flex-row flex-col items-center">
              <div className="flex gap-8">
                <div className="flex items-center gap-2">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                  />
                  <a href="mailto:info@almiswan.com">
                    info@almiswan.com
                  </a>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <span>Follow on:</span>
                <div className="flex items-center gap-2">
                  <FontAwesomeIcon icon={faFacebook} />
                  <FontAwesomeIcon icon={faInstagram} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav className="mt-4 relative z-30">
          <div className={`container mx-auto max-w-screen-2xl rounded-2xl ${location.pathname === '/' && 'border theme-dark-border-color'}`}>
            <div className="flex justify-between items-center p-5">
              <div className="logo" style={{ width: "10%", height: "50px" }}>
                <img
                  src="assets/images/almiswan-logo.svg"
                  alt="Almiswan"
                  className="h-full"
                />
              </div>
              <button
                data-collapse-toggle="mobile-menu"
                type="button"
                className="lg:hidden ml-3 text-gray-400 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-300 rounded-lg inline-flex items-center justify-center"
                aria-controls="mobile-menu-2"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <svg
                  className="hidden w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <div className="hidden lg:block w-full md:w-auto">
                <ul className="flex-col md:flex-row flex md:space-x-8 mt-4 md:mt-0">
                  <li>
                    <Link
                      to={"/"}
                      className={`${
                        location.pathname !== "/"
                          ? "text-gray-800"
                          : "text-gray-100"
                      } hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-gray-400 md:p-0`}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/about"}
                      className={`${
                        location.pathname !== "/"
                          ? "text-gray-800"
                          : "text-gray-100"
                      } hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-gray-400 md:p-0`}
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <button
                      className={`${
                        location.pathname !== "/"
                          ? "text-gray-800"
                          : "text-gray-100"
                      } hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-gray-400 md:p-0`}
                    >
                      Services
                    </button>
                  </li>
                  {/* <li>
                    <button
                      className={`${
                        location.pathname !== "/"
                          ? "text-gray-800"
                          : "text-gray-100"
                      } hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0`}
                    >
                      Shop
                    </button>
                  </li>
                  <li>
                    <Link
                    to={'/blog'}
                      className={`${
                        location.pathname !== "/"
                          ? "text-gray-800"
                          : "text-gray-100"
                      } hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0`}
                    >
                      Blog
                    </Link>
                  </li> */}
                  <li>
                    <a
                      href="/"
                      className={`${
                        location.pathname !== "/"
                          ? "text-gray-800"
                          : "text-gray-100"
                      } hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-gray-400 md:p-0`}
                    >
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
              <div className="hidden md:ml-6 lg:block">
                <div className="flex flex-row justify-center items-center gap-4">
                  <div
                    className={`border ${
                      location.pathname !== "/"
                        ? "border-gray-800"
                        : "border-gray-100"
                    } rounded-full py-2 px-3`}
                  >
                    <FontAwesomeIcon
                      icon={faPhoneVolume}
                      className={location.pathname !== "/" ? "text-black" : "text-white"}
                    />
                  </div>
                  <div
                    className={`flex flex-col gap-1 ${
                      location.pathname !== "/"
                        ? "text-gray-800"
                        : "text-gray-100"
                    }`}
                  >
                    <div>Talk With us:</div>
                    <div>+971 504773997</div>
                  </div>
                  <div className="flex flex-row gap-2 justify-center items-center">
                    <FontAwesomeIcon
                      icon={faGlobe}
                      className={location.pathname !== "/" ? "text-black" : "text-white"}
                    />
                    <select
                      className={`border-hidden bg-transparent ${
                        location.pathname !== "/"
                          ? "text-gray-800"
                          : "text-gray-100"
                      }`}
                    >
                      <option>English</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="mobile-menu"
            className="fixed bg-gray-500 sm:w-1/2 w-full h-full p-3 top-0 right-0 bg-white hidden"
          >
            <div className="flex justify-between">
              <img
                src="https://themes.envytheme.com/aegis/wp-content/uploads/2023/08/01.png"
                alt="Aegis"
              />
              <button data-collapse-toggle="mobile-menu">
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </div>
            <div className="flex flex-col gap-4 border-t pt-5 mt-3">
              <a href="/">Home</a>
              <a href="/">About</a>
              <a href="/">Services</a>
              <a href="/">Pages</a>
              <a href="/">Shop</a>
              <a href="/">Blog</a>
              <a href="/">Contact</a>
              <div className="flex items-center gap-2">
                <FontAwesomeIcon
                  icon={faPhoneVolume}
                  className={location.pathname !== "/" ? "text-black" : "text-white"}
                />
                <span>Talk with us:</span>
                <span>123123123123</span>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
